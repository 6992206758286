import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Header() {
    const [isEreadDropdownOpen, setEreadDropdownOpen] = useState(false);
    const [isResultsDropdownOpen, setResultsDropdownOpen] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (!event.target.closest('.dropdown-click')) {
                setEreadDropdownOpen(false);
                setResultsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const closeDropdowns = () => {
        setEreadDropdownOpen(false);
        setResultsDropdownOpen(false);
    };

    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
                <div className="flex">
                    {/* Логотип */}
                    <div className="shrink-0 flex items-center">
                        <Link to="/">
                            <div className="flex">
                                <img className="small-logo" src="/eu_logo.png" alt="Burn Logo" />
                                <img className="w-auto h-12" src="/burnLogo.png" alt="Burn Logo" />
                            </div>
                        </Link>
                    </div>

                    <div className="hidden space-x-8 sm:-my-px sm:ml-5 sm:flex">
                        <Link className="inline-flex items-center px-1 text-base font-medium leading-5 text-primary-custom dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 hover:border-gray-300 dark:hover:border-gray-700 focus:outline-none focus:text-gray-700 dark:focus:text-gray-300 focus:border-gray-300 dark:focus:border-gray-700 transition duration-150 ease-in-out" to="/about">
                            BURN project
                        </Link>

                        <div className="flex items-center">
                            <div className="relative">
                                <button className="text-base inline-flex px-2 py-1 items-center font-medium leading-5 rounded-md text-primary-custom dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 focus:outline-none transition ease-in-out duration-150" onClick={() => setEreadDropdownOpen(!isEreadDropdownOpen)}>
                                    <div>Network</div>
                                    <div className="ml-1">
                                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                        </svg>
                                    </div>
                                </button>
                                {isEreadDropdownOpen && (
                                    <div className="dropdown-click absolute z-50 mt-2 w-48 rounded-md shadow-lg origin-top-right right-0">
                                        <div className="rounded-md ring-1 ring-black ring-opacity-5 py-1 bg-white dark:bg-gray-700">
                                            <Link className="block w-full px-4 py-2 text-left text-base leading-5 text-gray-700 rounded-md hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out" to="/partners" onClick={closeDropdowns}>Partners</Link>
                                            <Link className="block w-full px-4 py-2 text-left text-base leading-5 text-gray-700 rounded-md hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out" to="/meet-the-team" onClick={closeDropdowns}>Meet the team</Link>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <Link className="inline-flex items-center px-1 text-base font-medium leading-5 text-primary-custom dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 hover:border-gray-300 dark:hover:border-gray-700 focus:outline-none focus:text-gray-700 dark:focus:text-gray-300 focus:border-gray-300 dark:focus:border-gray-700 transition duration-150 ease-in-out" to="/university-psychological-centers">
                        Center for Psychological Resilience and Wellbeing
                        </Link>

                        <Link className="inline-flex items-center px-1 text-base font-medium leading-5 text-primary-custom dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 hover:border-gray-300 dark:hover:border-gray-700 focus:outline-none focus:text-gray-700 dark:focus:text-gray-300 focus:border-gray-300 dark:focus:border-gray-700 transition duration-150 ease-in-out" to="/news-and-meeting">
                            News and events
                        </Link>

                        <Link className="inline-flex items-center px-1 text-base font-medium leading-5 text-primary-custom dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 hover:border-gray-300 dark:hover:border-gray-700 focus:outline-none focus:text-gray-700 dark:focus:text-gray-300 focus:border-gray-300 dark:focus:border-gray-700 transition duration-150 ease-in-out" to="/gallery">
                            Gallery
                        </Link>

                        <Link className="inline-flex items-center px-1 text-base font-medium leading-5 text-primary-custom dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 hover:border-gray-300 dark:hover:border-gray-700 focus:outline-none focus:text-gray-700 dark:focus:text-gray-300 focus:border-gray-300 dark:focus:border-gray-700 transition duration-150 ease-in-out" to="/contacts">
                            Contacts
                        </Link>

                        <div className="flex items-center">
                            <div className="relative">
                                <button className="text-base inline-flex bg-secondary-custom px-2 py-1 items-center px-1 font-medium leading-5 font-medium rounded-md text-white dark:text-gray-400 dark:bg-gray-800 hover:text-gray-700 dark:hover:text-gray-300 focus:outline-none transition ease-in-out duration-150" onClick={() => setResultsDropdownOpen(!isResultsDropdownOpen)}>
                                    <div>Results</div>
                                    <div className="ml-1">
                                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                        </svg>
                                    </div>
                                </button>
                                {isResultsDropdownOpen && (
                                    <div className="dropdown-click absolute z-50 mt-2 w-48 rounded-md shadow-lg origin-top-right right-0">
                                        <div className="rounded-md ring-1 ring-black ring-opacity-5 py-1 bg-white dark:bg-gray-700">
                                            <Link className="block w-full px-4 py-2 text-left text-base leading-5 text-gray-700 rounded-md hover:bg-primary-custom  focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out" to="/courses" onClick={closeDropdowns}>
                                                Courses
                                            </Link>
                                            <Link className="block w-full px-4 py-2 text-left text-base leading-5 text-gray-700 rounded-md hover:bg-primary-custom  focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out" to="/training-programs" onClick={closeDropdowns}>
                                                Training programs
                                            </Link>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex sm:flex items-center icon-container">
                            <a href="https://www.facebook.com/profile.php?id=61569858209910" rel="noreferrer" target="_blank" title="Facebook page">
                                <img src="/facebook.png" alt="Instagram" className="social-icon" />
                            </a>
                            <a href="https://www.linkedin.com/groups/12957674" rel="noreferrer" target="_blank" title="Instagram">
                                <img src="/linkedin.png" alt="LinkedIn" className="social-icon" />
                            </a>
                            <a href="https://www.researchgate.net/lab/BURN-project-Bondar-Kateryna" rel="noreferrer" target="_blank" title="Instagram">
                                <img src="/researchgate.png" alt="LinkedIn" className="social-icon" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Header;