function Footer() {
    return (
        <footer style={{ marginTop: "auto" }} className="bg-primary-custom">
            <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
                <div className="flex justify-between text-center">
                    <div className="flex items-center col-span-3 bg-white rounded-md p-3">
                        <img className="w-auto h-12" src="/eu_logo.png" alt="European Commission" />
                        Funded by the European Union. Views and opinions expressed are however those of the author(s) only and do not necessarily reflect those of the European Union or EACEA. Neither the European Union nor the granting authority can be held responsible for them.
                    </div>
                </div>
                <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
                <div className="flex justify-between items-center w-full">
                    <div className="text-sm text-white text-center">
                        © {new Date().getFullYear()} <a href="https://kdpu.edu.ua" className="hover:underline">Kryvyi Rih State Pedagogical University</a>. All Rights Reserved.
                    </div>
                    <div className="flex sm:flex items-center icon-container">
                            <a href="https://www.facebook.com/profile.php?id=61569858209910" rel="noreferrer" target="_blank" title="Facebook page">
                                <img src="/facebook.png" alt="Instagram" className="social-icon-footer" />
                            </a>
                            <a href="https://www.linkedin.com/groups/12957674" rel="noreferrer" target="_blank" title="Instagram">
                                <img src="/linkedin.png" alt="LinkedIn" className="social-icon-footer" />
                            </a>
                            <a href="https://www.researchgate.net/lab/BURN-project-Bondar-Kateryna" rel="noreferrer" target="_blank" title="Instagram">
                                <img src="/researchgate.png" alt="LinkedIn" className="social-icon-footer" />
                            </a>
                        </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
